import {
  ArrayField,
  Create,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  required,
} from "react-admin";
import {
  endOfDay,
  endOfMonth,
  parse,
  startOfDay,
  startOfMonth,
} from "date-fns";

import Box from "@mui/material/Box";
import { Timestamp } from "firebase/firestore";
import { centsToCurrency } from "../utils";

// Transform dates for Firestore query
const transformDateFilter = (filters: any) => {
  const transformed = { ...filters };

  if (filters.createdate_gte) {
    const startDate =
      typeof filters.createdate_gte === "string"
        ? parse(filters.createdate_gte, "yyyy-MM-dd", new Date())
        : filters.createdate_gte;
    transformed.createdate_gte = Timestamp.fromDate(startOfDay(startDate));
  }

  if (filters.createdate_lte) {
    const endDate =
      typeof filters.createdate_lte === "string"
        ? parse(filters.createdate_lte, "yyyy-MM-dd", new Date())
        : filters.createdate_lte;
    transformed.createdate_lte = Timestamp.fromDate(endOfDay(endDate));
  }

  return transformed;
};

export const OrderFilter = (props: any) => (
  <Filter {...props} transform={transformDateFilter}>
    <Box display="flex" gap={2} width="100%" flexWrap="wrap">
      <Box flex={1} minWidth={200}>
        <DateInput source="createdate_gte" label="Orders From" alwaysOn />
      </Box>
      <Box flex={1} minWidth={200}>
        <DateInput source="createdate_lte" label="Orders To" alwaysOn />
      </Box>
      <Box flex={1} minWidth={200}>
        <ReferenceInput label="Member" source="memberId" reference="members">
          <SelectInput optionText="displayName" />
        </ReferenceInput>
      </Box>
      <Box flex={1} minWidth={200}>
        <SelectInput
          source="status"
          choices={[
            { id: "COMPLETED", name: "Completed" },
            { id: "PENDING", name: "Pending" },
            { id: "CANCELLED", name: "Cancelled" },
          ]}
        />
      </Box>
      <Box flex={1} minWidth={200}>
        <TextInput label="Search Order ID" source="q" alwaysOn />
      </Box>
    </Box>
  </Filter>
);

export const OrderList = (props: any) => {
  const now = new Date();
  const defaultStartDate = startOfMonth(now);
  const defaultEndDate = endOfMonth(now);

  return (
    <List
      {...props}
      filters={<OrderFilter />}
      filterDefaultValues={{
        createdate_gte: defaultStartDate.toISOString().split("T")[0],
        createdate_lte: defaultEndDate.toISOString().split("T")[0],
      }}
      sort={{ field: "createdate", order: "DESC" }}
      perPage={50}
    >
      <Datagrid>
        <DateField source="createdate" label="Order Date" showTime />
        <ReferenceField source="memberId" reference="members" link="show">
          <TextField source="displayName" />
        </ReferenceField>
        <FunctionField
          label="Amount"
          render={(record: { amount: number }) =>
            centsToCurrency(record.amount)
          }
        />
        <TextField source="status" />
        <FunctionField
          label="Items"
          render={(record: any) =>
            record.lineItems
              ?.map((item: any) => `${item.name} (${item.quantity})`)
              .join(", ")
          }
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
export const OrderShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="createdate" label="Order Date" />
      <ReferenceField source="memberId" reference="members">
        <TextField source="displayName" />
        <br />
        <TextField source="email" />
      </ReferenceField>
      <TextField source="status" />
      <FunctionField
        source="amount"
        render={(record: { amount: number }) => centsToCurrency(record.amount)}
      />
      <TextField source="discount.code" />
      <ArrayField source="lineItems">
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <TextField source="sku" />
          <TextField source="eventName" />
          <TextField source="eventId" />
          <TextField source="amount" />
          <TextField source="quantity" />
        </Datagrid>
      </ArrayField>
      <FunctionField
        source="shippingAddress"
        render={(record: { shippingAddress: any }) => {
          if (!record.shippingAddress?.address) {
            return <p>No shipping address</p>;
          }
          return (
            <div>
              <p>{record.shippingAddress?.name}</p>
              <p>{record.shippingAddress?.address}</p>
              <p>
                {record.shippingAddress?.city}, {record.shippingAddress?.state}{" "}
                {record.shippingAddress?.zip} {record.shippingAddress?.country}
              </p>
            </div>
          );
        }}
      />
    </SimpleShowLayout>
  </Show>
);

export const OrderCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Member"
        source="memberId"
        reference="members"
        validate={[required()]}
      >
        <SelectInput optionText="displayName" />
      </ReferenceInput>

      <DateTimeInput
        source="createdate"
        defaultValue={new Date().toUTCString()}
      />
    </SimpleForm>
  </Create>
);

export const OrderEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput
        label="Member"
        source="memberId"
        reference="members"
        validate={[required()]}
      >
        <SelectInput optionText="displayName" />
      </ReferenceInput>
      <TextInput disabled source="createdate" />
      <TextInput disabled source="lastupdate" />
    </SimpleForm>
  </Edit>
);

const OrdersAdmin = {
  list: OrderList,
  show: OrderShow,
  edit: OrderEdit,
};
export default OrdersAdmin;
